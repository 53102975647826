export const bannerData = {
  title: {
    className: 'heading-1',
  },
  description: {
    text:
      'AGENT IMAGE is committed to respecting and protecting your privacy. To that end, we have prepared this privacy statement to disclose and describe our information gathering and dissemination practices for this website, www.agentimage.com.',
    className: 'subtitle-5',
  },
}

export const items = [
  {
    title: 'Notice and Disclosure',
    description: [
      'This privacy notice discloses the privacy practices for www.agentimage.com. This privacy notice applies solely to information collected by this web site. It will notify you of the following:',
      {
        hasCheck: false,
        subItems: [
          'What personally identifiable information is collected from you through the web site, how it is used and with whom it may be shared.',
          'What choices are available to you regarding the use of your data.',
          'The security procedures in place to protect the misuse of your information.',
          'How you can correct any inaccuracies in the information.',
        ],
      },
    ],
  },
  {
    title: 'Information Collection, Use, and Sharing',
    description: [
      'We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.',
      'In general, you may visit this website without identifying yourself or revealing any personal information. AGENT IMAGE collects domain information from your visit to customize and improve your experience on our website.',
      'Some portions of this website may require you to voluntarily provide personally identifiable information, which is information that enables us to identify you, such as your name, email or other address. If you opt to voluntarily provide with us that sort of information, we also may use it for purposes such as verifying your identity, sending you information or contacting you.',
      'We will use your information to respond to you regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request, e.g., to complete an order. For certain programs, we may make your information available to our business partners for marketing and other purposes which we believe may be beneficial to you.',
      'Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.',
    ],
  },
  {
    title: 'Your Access to and Control Over Information',
    description: [
      'You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:',
      {
        hasCheck: false,
        subItems: [
          'See what data we have about you, if any.',
          'Change/correct any data we have about you.',
          'Have us delete any data we have about you.',
          'Express any concern you have about our use of your data.',
        ],
      },
      'It is our intent to inform you before we collect personally identifiable information, and tell you what we intend to do with it. You will have the option to not provide the information, in which case you may still be able to access other portions of this website, although you may not be able to access and participate in certain programs or services.',
    ],
  },
  {
    title: 'Data Security',
    description: [
      'AGENT IMAGE’s intent is to strictly protect the security of your personal information; honor your choice for its intended use; and carefully protect your data from loss, misuse, unauthorized access or disclosure, alteration, or destruction. We have put in place physical, electronic and managerial procedures to safeguard and secure information we collect online, including the use of encryption when collecting or transferring sensitive data such as credit card information.',
      'We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.',
      'Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a closed lock icon at the bottom of your web browser, or looking for “https” at the beginning of the address of the web page.',
      'While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.',
      '<strong style="color: #000000;">If You Feel That We Are Not Abiding By This Privacy Policy, You Should Contact Us Immediately Via Telephone At <a href="tel:+1.877.317.4111" style="color: #0CA9C9;">1.877.317.4111</a> Or Via Email At <a href="mailto:support@agentimage.com" style="color: #0CA9C9;">Support@Agentimage.Com</a>.</strong>',
    ],
  },
  {
    title: 'Data Quality and Access',
    description: [
      'AGENT IMAGE strives to keep your information and data accurate, complete and current based on the information that you provide us. We will promptly take steps to correct any inaccuracies in your personally identifiable information of which you make us aware. Please contact support@agentimage.com should your information change and to update your contact information with us.',
    ],
  },
  {
    title: 'Orders',
    description: [
      'We request information from you on our order form. To buy from us, you must provide contact information (like name and address) and financial information (like credit card number, expiration date). This information is used for billing purposes and to complete your order(s). If we have trouble processing an order, we’ll use this information to contact you.',
    ],
  },
  {
    title: 'Cookies',
    description: [
      'We use “cookies” on this site. A cookie is a piece of data stored on a site visitor’s hard drive to help us improve your access to our site and identify repeat visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on our site. Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.',
    ],
  },
  {
    title: 'Additional Notices',
    description: [
      'We use an outside credit card processing company to bill users for goods and services. These companies do not retain, share, store or use personally identifiable information for any secondary purposes beyond filling your order.',
      'We partner or may partner with other parties to provide specific services. When the user signs up for these services, we will share names, or other contact information that is necessary for the third party to provide these services. These parties are not allowed to use personally identifiable information except for the purpose of providing these services.',
    ],
  },
  {
    title: 'Links and Business Relationships',
    description: [
      'This web site contains links to other websites. Please be aware that AGENT IMAGE is not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.',
    ],
  },
  {
    title: 'Surveys & Contests',
    description: [
      'From time­ to ­time our site requests information via surveys or contests. Participation in these surveys or contests is completely voluntary and you may choose whether or not to participate and therefore disclose this information. Information requested may include contact information (such as name and shipping address), and demographic information (such as zip code, age level). Contact information will be used to notify the winners and award prizes. Survey information will be used for purposes of monitoring or improving the use and satisfaction of this site. Surveys and contests are subject to change at any time without notice.',
    ],
  },
  {
    title: 'Public Forums',
    description: [
      'Portions of this website may now or in the future create chat rooms, forums, testimonials, social network posts, message boards, and/or news groups that will or may be made available to visitors. Please remember that any information that is disclosed in these areas becomes public information and exercise caution when deciding to disclose any personal information.',
    ],
  },
  {
    title: 'Contacting Us',
    description: [
      'If you have any questions or comments about our privacy statement or practices, please contact us via email at <a href="mailto:info@agentimage.com" style="font-weight: 400;">info@agentimage.com</a> with the words “PRIVACY POLICY” in the subject line or call <a href="tel:+1.877.317.4111" style="font-weight: 400;">1.877.317.4111</a>.',
      'CA reserves the right to modify or update this privacy statement at any time without prior notice. Last updated 10/16/2014.',
    ],
  },
]
