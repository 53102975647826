import React from 'react'
import { Link, graphql } from 'gatsby'
import useCompanyMetadata from '@hooks/company-metadata'
import parse from 'html-react-parser'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import { bannerData, items } from '@src/data/PrivacyPolicy'

import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import CompanyBanner from '@components/global/banner/Company'
import TermsPrivacy from '@components/global/terms-privacy'

const PrivacyPolicyTemplate = ({
  data: { page, bannerDevice },
  pageContext: { seo, uri },
}) => {
  const { TermsBannerBg } = useCompanyMetadata()

  return (
    <LayoutInnerPage hasBlackTransparentNav>
      <Seo title={page.title} seo={seo} uri={uri} />
      <CompanyBanner
        layout="alt"
        bannerBg={extractImage(TermsBannerBg)}
        breadcrumbs={page?.seo?.breadcrumbs}
        title={page.title}
        titleClassName={bannerData.title.className}
        subTitle={bannerData.description.text}
        subTitleClassName={bannerData.description.className}
      />
      <TermsPrivacy items={items} showIndex={false} isWideContainer={false} />
    </LayoutInnerPage>
  )
}

export default PrivacyPolicyTemplate

export const PrivacyPolicyPageQuery = graphql`
  query PrivacyPolicyPageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }
    }
  }
`
